<template>
  <div>
    <!-- add-entity-to-menu - add entities to the menu here -->
    <b-dropdown-item to="/corsi-block" active-class="active" class="custom-dropdown-item">
      <span> <font-awesome-icon icon="fa-solid fa-staris" />Corsi Blocks</span>
    </b-dropdown-item>
    <b-dropdown-item to="/goNoGo">
      <span>GoNoGo</span>
    </b-dropdown-item>
    <b-dropdown-item to="/n-back">
      <span>N-Back</span>
    </b-dropdown-item>
    <b-dropdown-item to="/tmt">
      <span>TMT</span>
    </b-dropdown-item>
  </div>
</template>

<style>
.custom-dropdown-item .dropdown-item-inner {
  border-radius: 5px; /* Adjust the value to change the radius */
}
</style>
<script lang="ts" src="./entities-menu.component.ts"></script>
