@import 'bootstrap-variables';

/* ==============================================================
Bootstrap tweaks
===============================================================*/

body,
h1,
h2,
h3,
h4 {
  font-weight: 300;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
}

.page-item button {
  color: #4120ab;
  border-color: #dee2e6;
}

.page-item.active .page-link {
  background-color: #4120ab !important;
  border-color: #4120ab;
}

.statisticsTable {
  background-color: white;
}

.statisticsTable tbody tr:nth-child(2n) {
  background-color: #f2f2f2;
}

.statisticsTable tbody tr:nth-child(2n) {
  background-color: #f2f2f2;
}

a {
  color: #533f03;
  font-weight: bold;
}

a:hover {
  color: #533f03;
  /* make sure browsers use the pointer cursor for anchors, even with no href */
  cursor: pointer;
}
.dropdown-item:hover,
.dropdown-item:focus {
  color: white;
  text-decoration: none;
  background-color: #373a3c;
}

/* ==========================================================================
Browser Upgrade Prompt
========================================================================== */
.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

/* ==========================================================================
Main page styles
========================================================================== */

.hipster.img-fluid {
  display: inline-block;
  width: 347px;
  height: 350px;
  background: url('../images/actress3.png') no-repeat center top;
  background-size: contain;
}

/* wait autoprefixer update to allow simple generation of high pixel density media query */
@media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi),
  only screen and (min-resolution: 2dppx) {
  .hipster {
    background: url('../images/jhipster_family_member_1.svg') no-repeat center top;
    background-size: contain;
  }
}

/* ==========================================================================
Generic styles
========================================================================== */

/* Error highlight on input fields */
.valid[required],
.valid.required {
  border-left: 5px solid green;
}

.invalid:not(form) {
  border-left: 5px solid red;
}

.modal-content {
  border-radius: 10px !important;
}

/* other generic styles */

.jh-card {
  padding: 1.5%;
  margin-top: 20px;
  border: none;
  background: inherit;
}

.error {
  color: white;
  background-color: red;
}

.pad {
  padding: 10px;
}

.w-40 {
  width: 40% !important;
}

.w-60 {
  width: 60% !important;
}

.break {
  white-space: normal;
  word-break: break-all;
}

.readonly {
  background-color: #eee;
  opacity: 1;
}

.footer {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.hand,
[jhisortby] {
  cursor: pointer;
}

/* ==========================================================================
Custom alerts for notification
========================================================================== */
.alerts {
  .alert {
    text-overflow: ellipsis;
    pre {
      background: none;
      border: none;
      font: inherit;
      color: inherit;
      padding: 0;
      margin: 0;
    }
    .popover pre {
      font-size: 10px;
    }
  }
  .toast {
    position: fixed;
    width: 100%;
    &.left {
      left: 5px;
    }
    &.right {
      right: 5px;
    }
    &.top {
      top: 55px;
    }
    &.bottom {
      bottom: 55px;
    }
  }
}

@media screen and (min-width: 480px) {
  .alerts .toast {
    width: 50%;
  }
}

/* ==========================================================================
entity tables helpers
========================================================================== */

/* Remove Bootstrap padding from the element
http://stackoverflow.com/questions/19562903/remove-padding-from-columns-in-bootstrap-3 */
@mixin no-padding($side) {
  @if $side == 'all' {
    .no-padding {
      padding: 0 !important;
    }
  } @else {
    .no-padding-#{$side} {
      padding-#{$side}: 0 !important;
    }
  }
}
@include no-padding('left');
@include no-padding('right');
@include no-padding('top');
@include no-padding('bottom');
@include no-padding('all');

/* bootstrap 3 input-group 100% width
http://stackoverflow.com/questions/23436430/bootstrap-3-input-group-100-width */
.width-min {
  width: 1% !important;
}

/* ==========================================================================
entity detail page css
========================================================================== */
.row.jh-entity-details {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 10px;
  line-height: 1.5;
}

@media screen and (min-width: 768px) {
  .row.jh-entity-details > {
    dt {
      float: left;
      overflow: hidden;
      clear: left;
      text-align: right;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 0.5em 0;
    }
    dd {
      border-bottom: 1px solid #eee;
      padding: 0.5em 0;
      margin-left: 0;
    }
  }
}

/* ==========================================================================
ui bootstrap tweaks
========================================================================== */
.nav,
.pagination,
.carousel,
.card-title a {
  cursor: pointer;
}

.datetime-picker-dropdown > li.date-picker-menu div > table .btn-secondary,
.uib-datepicker-popup > li > div.uib-datepicker > table .btn-secondary {
  border: 0;
}

.datetime-picker-dropdown > li.date-picker-menu div > table:focus,
.uib-datepicker-popup > li > div.uib-datepicker > table:focus {
  outline: none;
}

.thread-dump-modal-lock {
  max-width: 450px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#user-management-page-heading {
  font-size: 0.9em;
  font-family: sans-serif;
  font-weight: bold;
}
.user-table {
  width: 100%;
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

.user-table thead tr {
  background-color: #009879;
  color: #ffffff;
  text-align: left;
  border-radius: 10px;
}

.user-table th:first-child {
  border-top-left-radius: 10px;
}

.user-table th:last-child {
  border-top-right-radius: 10px;
}

.user-table th,
.user-table td {
  padding: 12px 15px;
}

.user-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.user-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.user-table tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}

.user-table tbody tr.active-row {
  font-weight: bold;
  color: #009879;
}

select {
  border: 1px solid #ccc;
  outline: none;
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #555;
  background: #fff;
  border-radius: 1px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  -o-border-radius: 10px;
}

select:focus {
  box-shadow: none;
}

select option {
  font-size: 16px;
  font-weight: bold;
  color: #555;
  background: #fff;
  border-radius: 1px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  -o-border-radius: 10px;
}

select option:checked {
  background: #555;
  color: #fff;
}

input[type='text'],
input[type='password'],
input[type='date'],
input[type='email'],
textarea {
  border: none;
  outline: none;
  padding: 10px;
  font-size: 12px;
  color: #555;
  background: #fff;
  border-radius: 20px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  -o-border-radius: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

input[type='submit'],
.common_button {
  border: none;
  outline: none;
  padding: 10px 20px;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  background: #555;
  border-radius: 20px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  -o-border-radius: 10px;
  cursor: pointer;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.jh-card {
  border-radius: 15px !important;
}
.d-md-inline:hover {
  background: rgb(176, 154, 248);
}

.w-btn {
  position: relative;
  border: none;
  display: inline-block;
  padding: 5px 20px;
  border-radius: 15px;
  font-family: 'paybooc-Light', sans-serif;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.2);
  text-decoration: none;
  font-weight: 600;
  transition: 0.25s;
}

.w-btn-outline {
  position: relative;
  padding: 15px 30px;
  border-radius: 15px;
  font-family: 'paybooc-Light', sans-serif;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.2);
  text-decoration: none;
  font-weight: 600;
  transition: 0.25s;
}

.w-btn-indigo {
  background-color: aliceblue;
  color: #1e6b7b;
}

.w-btn-indigo-outline {
  border: 3px solid aliceblue;
  color: #1e6b7b;
}

.w-btn-indigo-outline:hover {
  color: #1e6b7b;
  background: aliceblue;
}

.w-btn-green {
  background-color: #77af9c;
  color: #d7fff1;
}

.w-btn-green2 {
  background-color: #519d9e;
  color: #9dc8c8;
}

.w-btn-green-outline {
  border: 3px solid #77af9c;
  color: darkgray;
}

.w-btn-green2-outline {
  border: 3px solid #519d9e;
  color: darkgray;
}

.w-btn-green-outline:hover {
  background-color: #77af9c;
  color: #d7fff1;
}

.w-btn-green2-outline:hover {
  background-color: #519d9e;
  color: #9dc8c8;
}

.w-btn-brown {
  background-color: #ce6d39;
  color: #ffeee4;
}

.w-btn-brown-outline {
  border: 3px solid #ce6d39;
  color: #b8b8b8;
}

.w-btn-brown-outline:hover {
  background-color: #ce6d39;
  color: #ffeee4;
}

.w-btn-blue {
  background-color: #6aafe6;
  color: #d4dfe6;
}

.w-btn-pink {
  background-color: #f199bc;
  color: #d4dfe6;
}

.w-btn-gray {
  background-color: #a3a1a1;
  color: #e3dede;
}

.w-btn-red {
  background-color: #ff5f2e;
  color: #e1eef6;
}

.w-btn-skin {
  background-color: #f8e6e0;
  color: #6e6e6e;
}

.w-btn-yellow {
  background-color: #fce205;
  color: #6e6e6e;
}

.w-btn-blue-outline {
  border: 3px solid #6aafe6;
  color: #6e6e6e;
}

.w-btn-pink-outline {
  border: 3px solid #f199bc;
  color: #6e6e6e;
}

.w-btn-gray-outline {
  border: 3px solid #a3a1a1;
  color: #6e6e6e;
}

.w-btn-red-outline {
  border: 3px solid #ff5f2e;
  color: #6e6e6e;
}

.w-btn-skin-outline {
  border: 3px solid #f8e6e0;
  color: #6e6e6e;
}

.w-btn-yellow-outline {
  border: 3px solid #fce205;
  color: #6e6e6e;
}

.w-btn-blue-outline:hover {
  background-color: #6aafe6;
  color: #d4dfe6;
}

.w-btn-pink-outline:hover {
  background-color: #f199bc;
  color: #d4dfe6;
}

.w-btn-gray-outline:hover {
  background-color: #a3a1a1;
  color: #e3dede;
}

.w-btn-red-outline:hover {
  background-color: #ff5f2e;
  color: #e1eef6;
}

.w-btn-skin-outline:hover {
  background-color: #f8e6e0;
  color: #6e6e6e;
}

.w-btn-yellow-outline:hover {
  background-color: #fce205;
  color: #6e6e6e;
}

.w-btn:hover {
  letter-spacing: 2px;
  transform: scale(1.2);
  cursor: pointer;
}

.w-btn-outline:hover {
  letter-spacing: 2px;
  transform: scale(1.2);
  cursor: pointer;
}

.w-btn:active {
  transform: scale(1.5);
}

.w-btn-outline:active {
  transform: scale(1.5);
}

.w-btn-gra1 {
  background: linear-gradient(-45deg, #33ccff 0%, #ff99cc 100%);
  color: white;
}

.w-btn-gra2 {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  color: white;
}

.w-btn-gra3 {
  background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
  color: white;
}

.w-btn-gra-anim {
  background-size: 400% 400%;
  animation: gradient1 5s ease infinite;
}

@keyframes gradient1 {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes gradient2 {
  0% {
    background-position: 100% 50%;
  }
  50% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

@keyframes ring {
  0% {
    width: 30px;
    height: 30px;
    opacity: 1;
  }
  100% {
    width: 300px;
    height: 300px;
    opacity: 0;
  }
}

.w-btn-neon2 {
  position: relative;
  border: none;
  min-width: 200px;
  min-height: 50px;
  background: linear-gradient(90deg, rgba(129, 230, 217, 1) 0%, rgba(79, 209, 197, 1) 100%);
  border-radius: 1000px;
  color: darkslategray;
  cursor: pointer;
  box-shadow: 12px 12px 24px rgba(79, 209, 197, 0.64);
  font-weight: 700;
  transition: 0.3s;
}

.w-btn-neon2:hover {
  transform: scale(1.2);
}

.w-btn-neon2:hover::after {
  content: '';
  width: 30px;
  height: 30px;
  border-radius: 100%;
  border: 6px solid #00ffcb;
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: ring 1.5s infinite;
}

/* jhipster-needle-scss-add-main JHipster will add new css style */
