<template>
  <div class="modal-body">
    <div class="row justify-content-center">
      <div class="col-md-8">
        <b-alert show data-cy="loginError" variant="danger" v-if="authenticationError">
          <strong>로그인에 실패 하였습니다!</strong>
          <br />계정과 비밀번호를 다시 확인해주세요.
        </b-alert>
      </div>
      <div class="col-md-8">
        <b-form role="form" v-on:submit.prevent="doLogin()">
          <b-form-group label="계정" label-for="username">
            <b-form-input
              id="username"
              type="text"
              name="username"
              autofocus
              placeholder="계정을 입력해주세요."
              v-model="email"
              data-cy="username"
            >
            </b-form-input>
          </b-form-group>
          <b-form-group label="비밀번호" label-for="password">
            <b-form-input
              id="password"
              type="password"
              name="password"
              v-model.trim="name"
              placeholder="비밀번호를 입력해주세요."
              v-model="password"
              data-cy="password"
            >
            </b-form-input>
          </b-form-group>
          <div>
            <b-button data-cy="submit" type="submit" class="my-button">접속</b-button>
          </div>
        </b-form>
        <p></p>
      </div>
    </div>
  </div>
</template>
<style>
.my-button.btn {
  border: none;
  outline: none;
  padding: 10px 20px;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  background: #555;
  border-radius: 20px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  -o-border-radius: 10px;
  cursor: pointer;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  background-color: #77af9c;
  color: #d7fff1;
}

.my-button.btn {
  border: 3px solid #4120ab;
  color: #f5f5f5;
}

.my-button.btn {
  background-color: #4120ab;
  color: #f5f5f5;
}
</style>

<script lang="ts" src="./login-form.component.ts"></script>
